import AuthorList from '../book/authors/AuthorList';
import Wrapper from '../../components/Wrapper';
import React from 'react';
import { ItemDto } from '../../types/global';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Link, useNavigate } from 'react-router-dom';
import ButtonDialog from '../../components/Widgets/ButtonDialog';
import { api, itemService } from '../../_services';
import ItemAddLink from './ItemAddLink';
import Button from '@mui/material/Button';

type DetailLanguageProps = {
	media: ItemDto;
	onChange: () => void;
};

export const DetailLanguage = ({ media, onChange }: DetailLanguageProps) => {
	const navigate = useNavigate();
	return (
		<Wrapper sectionHeading='Autre langues'>
			<Table>
				{media.otherLanguage?.map((value) => (
					<TableRow key={value.id}>
						<TableCell>
							<Link to={`/items/detail/${value.id}`}>
								[{value.lang}] {value.title}
							</Link>
						</TableCell>
						<TableCell>
							&nbsp;
							<ButtonDialog
								text='Fusioner'
								size='small'
								variant='contained'
								confirmBtnCancel='Annuler'
								confirmBtnValid='Fusionner'
								confirmTitle='Souhaitez vous fusioner sur cette item ?'
								disableElevation
								type='submit'
								color='primary'
								onValid={() =>
									itemService.fusion(media.id, value.id).then(() => {
										navigate(`/items/detail/${value.id}`);
									})
								}
							/>
							<ButtonDialog
								text='Supprimer'
								onValid={() =>
									void api
										.call('DELETE', `/admin/item//${value.id}/otherlanguage/`)
										.then(() => onChange())
								}
								confirmBtnCancel='Annuler'
								confirmBtnValid='Supprimer'
								confirmTitle='Souhaitez vous supprimer ce lien ?'
							/>
						</TableCell>
					</TableRow>
				))}
			</Table>
			&nbsp;
			<ItemAddLink
				media={media}
				onAdd={(newid) => {
					void api
						.call('PUT', `/admin/item//${media.id}/otherlanguage/${newid}/`)
						.then(() => onChange());
				}}
			/>
		</Wrapper>
	);
};
