import {
	QueryClient,
	QueryFunctionContext,
	QueryKey,
	useInfiniteQuery,
	UseInfiniteQueryOptions,
	UseInfiniteQueryResult,
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from 'react-query';
import { convertToQueryString, Reponse } from '../../utils/request';
import { NewsDetailLinkDto, NewsDto, NewsLinkAdminDto, SearchResponse } from '../../types/global';
import { api } from '../../_services';
import { NewsPageFilter } from './News';

export const resetNews = async (queryClient: QueryClient) => {
	await queryClient.invalidateQueries({ queryKey: ['news'] });
};

export const useNews = (
	filters?: NewsPageFilter,
	options: UseInfiniteQueryOptions<Reponse<SearchResponse<NewsDto>>> = {},
): UseInfiniteQueryResult<Reponse<SearchResponse<NewsDto>>> => {
	const queryString = convertToQueryString({
		...filters,
		limit: 30,
	});
	return useInfiniteQuery<Reponse<SearchResponse<NewsDto>>>(
		['news', 'list', queryString],
		({ pageParam = 0 }: QueryFunctionContext<QueryKey, number>) =>
			api.call('GET', `/admin/news/?page=${pageParam / 30}&${queryString}`) as Promise<
				Reponse<SearchResponse<NewsDto>>
			>,
		{
			...options,
			getNextPageParam: (lastPage) => {
				return lastPage.data.nextOffset;
			},
		},
	);
};

export const useNewsId = (
	id: number,
	options: UseQueryOptions<Reponse<NewsDetailLinkDto>> = {},
): UseQueryResult<Reponse<NewsDetailLinkDto>> => {
	return useQuery<Reponse<NewsDetailLinkDto>>(
		['news', 'id', id.toString()],
		() => api.call('GET', `/news/${id}/`) as Promise<Reponse<NewsDetailLinkDto>>,
		{
			...options,
			enabled: id > 0,
		},
	);
};
export const useNewsLinkAuthor = (
	newsId: number,
	options: UseQueryOptions<Reponse<NewsLinkAdminDto[]>> = {},
): UseQueryResult<Reponse<NewsLinkAdminDto[]>> => {
	return useQuery<Reponse<NewsLinkAdminDto[]>>(
		['news', 'link', 'author', newsId.toString()],
		() =>
			api.call('GET', `/admin/news/${newsId}/author/`) as Promise<
				Reponse<NewsLinkAdminDto[]>
			>,
		{
			...options,
			enabled: newsId > 0,
		},
	);
};
export const useNewsLinkSerie = (
	newsId: number,
	options: UseQueryOptions<Reponse<NewsLinkAdminDto[]>> = {},
): UseQueryResult<Reponse<NewsLinkAdminDto[]>> => {
	return useQuery<Reponse<NewsLinkAdminDto[]>>(
		['news', 'link', 'serie', newsId.toString()],
		() =>
			api.call('GET', `/admin/news/${newsId}/serie/`) as Promise<Reponse<NewsLinkAdminDto[]>>,
		{
			...options,
			enabled: newsId > 0,
		},
	);
};
export const useNewsLinkItems = (
	newsId: number,
	options: UseQueryOptions<Reponse<NewsLinkAdminDto[]>> = {},
): UseQueryResult<Reponse<NewsLinkAdminDto[]>> => {
	return useQuery<Reponse<NewsLinkAdminDto[]>>(
		['news', 'link', 'item', newsId.toString()],
		() =>
			api.call('GET', `/admin/news/${newsId}/item/`) as Promise<Reponse<NewsLinkAdminDto[]>>,
		{
			...options,
			enabled: newsId > 0,
		},
	);
};
