import React, { ReactNode, useEffect, useState } from 'react';
import PubSub from 'pubsub-js';
import Alert from '@mui/material/Alert';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { itemService } from '../_services';
import ButtonDialog from './Widgets/ButtonDialog';

const regexItem = /.*item = ([0-9]+) -.*/;
const Errors = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [init, setInit] = useState<PubSubJS.Token>();
	const [msg, setMsg] = useState<ReactNode | string | null>(null);

	useEffect(() => {
		if (!init) {
			const mySubscriber = (message: string, data: string) => {
				const match = data.match(regexItem);
				if (match != null) {
					setMsg(
						<>
							{data}&nbsp;
							<Button
								size='small'
								variant='contained'
								disableElevation
								type='submit'
								color='primary'
								onClick={() => navigate('/items/detail/' + match[1])}>
								Voir l&apos;item
							</Button>
							&nbsp;
							<ButtonDialog
								text='Fusioner'
								size='small'
								variant='contained'
								confirmBtnCancel='Annuler'
								confirmBtnValid='Fusionner'
								confirmTitle='Souhaitez vous fusioner sur cette item ?'
								disableElevation
								type='submit'
								color='primary'
								onValid={() =>
									itemService.fusion(Number(id), Number(match[1])).then(() => {
										navigate('/items/detail/' + match[1]);
									})
								}
							/>
						</>,
					);
				} else {
					setMsg(data);
				}
			};

			// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
			setInit(PubSub.subscribe('app_error', mySubscriber));
		}
		return () => {
			if (init) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
				PubSub.unsubscribe(init);
			}
		};
	}, [init]);

	return msg ? (
		<div className='app-alert'>
			<Alert
				severity='error'
				onClose={() => {
					setMsg(null);
				}}>
				{msg}
			</Alert>
		</div>
	) : null;
};
export default Errors;
