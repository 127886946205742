import Wrapper from '../../components/Wrapper';
import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useSyncFormWithUrl } from '../../utils/useSyncFormWithUrl';
import PageTitle from '../../layout-components/PageTitle';
import QueryNextPage from '../../components/QueryNextPage';
import {
	resetNews,
	useNews,
	useNewsId,
	useNewsLinkAuthor,
	useNewsLinkItems,
	useNewsLinkSerie,
} from './use-news';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import SerieList from '../book/series/SerieList';
import SerieGroupAdd from '../book/seriesGroup/SerieGroupAdd';
import NewsSerieAdd from './NewsSerieAdd';
import { ValidationBloc } from '../../components/ValidationBloc';
import { EditionValidationType, NewsLinkAdminDto, ValidationState } from '../../types/global';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { api } from '../../_services';
import { useQueryClient } from 'react-query';
import NewsAuthorAdd from './NewsAuthorAdd';
import NewsItemsAdd from './NewsItemsAdd';

const row = (
	newsId: number,
	s: NewsLinkAdminDto,
	key: string,
	detailLink: string,
	refetch: () => void,
) => {
	return (
		<TableRow>
			<TableCell>
				<Link to={detailLink}>
					{s.id} - {s.title}
				</Link>
			</TableCell>
			<TableCell>{s.linkSource}</TableCell>
			<TableCell>
				<ValidationBloc
					state={s.validationState}
					onValidate={() =>
						api
							.call('PUT', `/admin/news/${newsId}/${key}/${s.id}/validation/`, {
								state: ValidationState.ACCEPTED,
							})
							.then(refetch)
					}
					onRefuse={() =>
						api
							.call('PUT', `/admin/news/${newsId}/${key}/${s.id}/validation/`, {
								state: ValidationState.REFUSED,
							})
							.then(refetch)
					}
				/>
				<Button
					onClick={() =>
						api
							.call('DELETE', `/admin/news/${newsId}/${key}/${s.id}/`, {
								state: ValidationState.REFUSED,
							})
							.then(refetch)
					}
					color={'error'}
					variant='outlined'
					size='small'
					sx={{
						minWidth: '20px',
					}}>
					Supprimer
				</Button>
			</TableCell>
		</TableRow>
	);
};

export const NewsDetail = () => {
	const queryClient = useQueryClient();
	const { id } = useParams();
	const { data: news } = useNewsId(Number(id));
	const { data: newsAuthor } = useNewsLinkAuthor(Number(id));
	const { data: newsSerie } = useNewsLinkSerie(Number(id));
	const { data: newsItems } = useNewsLinkItems(Number(id));
	const refetch = () => resetNews(queryClient);

	if (!news || !news.data) {
		return null;
	}

	return (
		<>
			<PageTitle
				titleHeading={news.data.news.title}
				titleDescription={news.data.news.source}
			/>
			<Wrapper>
				<Typography variant={'body1'}>{news.data.news.desc}</Typography>
				<br />
				<Typography variant={'caption'}>
					<a href={news.data.news.link}>{news.data.news.link}</a>
				</Typography>
			</Wrapper>

			<Wrapper sectionHeading='Actions'>
				<Button
					variant='contained'
					disableElevation
					onClick={() =>
						api.call('PUT', `/admin/news/${news.data.news.id}/refetch/tags/`)
					}>
					Recalcul des links
				</Button>
			</Wrapper>
			<Wrapper sectionHeading={'Séries'}>
				<Table size='small' className='table-responsive'>
					{newsSerie?.data.map((s) =>
						row(news.data.news.id, s, 'serie', `/book/series/${s.id}`, refetch),
					)}
				</Table>
				<NewsSerieAdd news={news.data} onChange={refetch} />
			</Wrapper>
			<Wrapper sectionHeading={'Items'}>
				<Table size='small' className='table-responsive'>
					{newsItems?.data.map((s) =>
						row(news.data.news.id, s, 'item', `/items/detail/${s.id}`, refetch),
					)}
				</Table>
				<NewsItemsAdd news={news.data} onChange={refetch} />
			</Wrapper>
			<Wrapper sectionHeading={'Auteurs'}>
				<Table size='small' className='table-responsive'>
					{newsAuthor?.data.map((s) =>
						row(news.data.news.id, s, 'author', `/book/authors/${s.id}`, refetch),
					)}
				</Table>
				<NewsAuthorAdd news={news.data} onChange={refetch} />
			</Wrapper>
		</>
	);
};
