import Wrapper from '../../components/Wrapper';
import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useSyncFormWithUrl } from '../../utils/useSyncFormWithUrl';
import PageTitle from '../../layout-components/PageTitle';
import QueryNextPage from '../../components/QueryNextPage';
import { useNews } from './use-news';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { api, searchService } from '../../_services';
import Alert from '@mui/material/Alert';
import { useTypedSearchParams } from '../../utils/useTypedSearchParams';

type NewsPageParams = {
	override?: string;
};
export type NewsPageFilter = {
	override?: boolean;
};
export const News = () => {
	const [searchParam] = useTypedSearchParams<NewsPageParams>();

	const methods = useForm<NewsPageFilter>({
		mode: 'onChange',
		defaultValues: {
			override: Boolean(searchParam.override ?? false),
		},
	});
	const {
		data: news,
		hasNextPage,
		isFetchingNextPage,
		fetchNextPage,
		refetch,
	} = useNews(methods.getValues());

	useSyncFormWithUrl(methods.watch);

	return (
		<>
			<PageTitle titleHeading='Actualitées' titleDescription='' />

			<Wrapper>
				<FormProvider {...methods}>
					<div>
						<label>
							<input type='checkbox' {...methods.register('override')} /> &nbsp;Voir
							que les surcharges
						</label>
					</div>
				</FormProvider>
			</Wrapper>
			<Wrapper sectionHeading='Actions'>
				<Button
					variant='contained'
					disableElevation
					onClick={() => api.call('PUT', '/admin/news/refetch/')}>
					Refetch RSS
				</Button>
				&nbsp;
				<Button
					variant='contained'
					disableElevation
					onClick={() => api.call('PUT', '/admin/news/refetch/tags/')}>
					Recalcul des links
				</Button>
			</Wrapper>
			<Wrapper>
				<Table size='small' className='table-responsive'>
					<TableHead>
						<TableRow>
							<TableCell>Titre</TableCell>
							<TableCell>Source</TableCell>
							<TableCell>Date</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{news?.pages.map((e) =>
							e.data.objects.map((n) => (
								<TableRow>
									<TableCell>
										<Link to={`/news/${n.id}`}>{n.title}</Link>
									</TableCell>
									<TableCell>{n.source}</TableCell>
									<TableCell>{n.date}</TableCell>
								</TableRow>
							)),
						)}
					</TableBody>
				</Table>
				<QueryNextPage
					hasNextPage={hasNextPage}
					isFetchingNextPage={isFetchingNextPage}
					fetchNextPage={fetchNextPage}
				/>
			</Wrapper>
		</>
	);
};
